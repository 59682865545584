import React from "react"
import { loadStripe } from "@stripe/stripe-js"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Title, Flex, Button, Text, Box } from "../components/system"
import Player from "../components/Player"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "wall.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const redirectToCheckout = async e => {
    e.preventDefault()
    const stripe = await loadStripe(process.env.GATSBY_STRIPE_PUBLIC)
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: "price_1HV0j3FR9zaIZsJNRjK20fyE",
          quantity: 1,
        },
      ],
      mode: "payment",
      successUrl: `http://twbtc.co.uk/`,
      cancelUrl: `http://twbtc.co.uk/`,
      shippingAddressCollection: {
        allowedCountries: [
          "AC",
          "AD",
          "AE",
          "AF",
          "AG",
          "AI",
          "AL",
          "AM",
          "AO",
          "AQ",
          "AR",
          "AT",
          "AU",
          "AW",
          "AX",
          "AZ",
          "BA",
          "BB",
          "BD",
          "BE",
          "BF",
          "BG",
          "BH",
          "BI",
          "BJ",
          "BL",
          "BM",
          "BN",
          "BO",
          "BQ",
          "BR",
          "BS",
          "BT",
          "BV",
          "BW",
          "BY",
          "BZ",
          "CA",
          "CD",
          "CF",
          "CG",
          "CH",
          "CI",
          "CK",
          "CL",
          "CM",
          "CN",
          "CO",
          "CR",
          "CV",
          "CW",
          "CY",
          "CZ",
          "DE",
          "DJ",
          "DK",
          "DM",
          "DO",
          "DZ",
          "EC",
          "EE",
          "EG",
          "EH",
          "ER",
          "ES",
          "ET",
          "FI",
          "FJ",
          "FK",
          "FO",
          "FR",
          "GA",
          "GB",
          "GD",
          "GE",
          "GF",
          "GG",
          "GH",
          "GI",
          "GL",
          "GM",
          "GN",
          "GP",
          "GQ",
          "GR",
          "GS",
          "GT",
          "GU",
          "GW",
          "GY",
          "HK",
          "HN",
          "HR",
          "HT",
          "HU",
          "ID",
          "IE",
          "IL",
          "IM",
          "IN",
          "IO",
          "IQ",
          "IS",
          "IT",
          "JE",
          "JM",
          "JO",
          "JP",
          "KE",
          "KG",
          "KH",
          "KI",
          "KM",
          "KN",
          "KR",
          "KW",
          "KY",
          "KZ",
          "LA",
          "LB",
          "LC",
          "LI",
          "LK",
          "LR",
          "LS",
          "LT",
          "LU",
          "LV",
          "LY",
          "MA",
          "MC",
          "MD",
          "ME",
          "MF",
          "MG",
          "MK",
          "ML",
          "MM",
          "MN",
          "MO",
          "MQ",
          "MR",
          "MS",
          "MT",
          "MU",
          "MV",
          "MW",
          "MX",
          "MY",
          "MZ",
          "NA",
          "NC",
          "NE",
          "NG",
          "NI",
          "NL",
          "NO",
          "NP",
          "NR",
          "NU",
          "NZ",
          "OM",
          "PA",
          "PE",
          "PF",
          "PG",
          "PH",
          "PK",
          "PL",
          "PM",
          "PN",
          "PR",
          "PS",
          "PT",
          "PY",
          "QA",
          "RE",
          "RO",
          "RS",
          "RU",
          "RW",
          "SA",
          "SB",
          "SC",
          "SE",
          "SG",
          "SH",
          "SI",
          "SJ",
          "SK",
          "SL",
          "SM",
          "SN",
          "SO",
          "SR",
          "SS",
          "ST",
          "SV",
          "SX",
          "SZ",
          "TA",
          "TC",
          "TD",
          "TF",
          "TG",
          "TH",
          "TJ",
          "TK",
          "TL",
          "TM",
          "TN",
          "TO",
          "TR",
          "TT",
          "TV",
          "TW",
          "TZ",
          "UA",
          "UG",
          "US",
          "UY",
          "UZ",
          "VA",
          "VC",
          "VE",
          "VG",
          "VN",
          "VU",
          "WF",
          "WS",
          "XK",
          "YE",
          "YT",
          "ZA",
          "ZM",
          "ZW",
          "ZZ",
        ],
      },
    })
    if (error) {
      console.warn("Error:", error)
    }
  }
  return (
    <Layout>
      <SEO title="The wall between two classes" />
      <Flex
        bg="primary"
        color="white"
        px={[4, 5, 6]}
        py={[5, 5, 6]}
        flexDirection="column"
      >
        <Title
          fontSize={[6, 6, 7]}
          textTransform="uppercase"
          style={{ textTransform: "uppercase" }}
        >
          The wall between two classes
        </Title>
        <Text mb={4} color="#494d50">
          “All walls are meant to be climbed, especially the invisible one”
        </Text>

        <Button
          variant="filled"
          fontWeight={700}
          onClick={e => redirectToCheckout(e)}
        >
          Order Now
        </Button>
      </Flex>
      <Player />
      <Box bg="primary" color="white" p={[4, 5, 7]}>
        <Text
          fontSize={[2, 4]}
          lineHeight={1.5}
          fontWeight={300}
          textAlign="left"
        >
          I wonder if we ask ourselves what separates the two classes, what
          separates the rich from the poor and what separates the ones who
          survive from the ones who live. <br /> <br /> I've come to realise
          that it is not just as simple as finding out that it is white chalked
          line that separates this side from that side or cutting a cake in half
          that separates one slice to be mine and one slice to be yours or even
          a full sized built bricked wall separating one side of the wall from
          the other side of that wall. It's rather much complicated than that,
          it's coming to the realisation that you’ll never see a clear white
          chalked line separating or as of yet see a physical built wall
          separating the rich from the poor or separating the ones that just
          survive from the ones that live. It's coming to the realisation that
          the thing that is separating you between the two sides is you.
        </Text>
      </Box>
      <Box position="relative">
        <Img fluid={data.placeholderImage.childImageSharp.fluid} />
        <Flex position="absolute" top={30} p={[4, 5, 7]}>
          <Text
            mb={4}
            color="white"
            fontSize={[3, 4, 5]}
            lineHeight={1.5}
            fontWeight={300}
          >
            A engaging concept written by the veiled writer{" "}
            <b>Richmond Ayoola - Releases on the 27th November</b> Signed
            hardcopies available to order
          </Text>
        </Flex>
      </Box>
      <Flex bg="primary" color="white" p={5}>
        <a href="https://www.instagram.com/twbtc_/">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4777 2.3937C18.8375 2.39776 20.1404 2.93973 21.1019 3.90125C22.0635 4.86277 22.6054 6.16571 22.6095 7.5255V17.788C22.6054 19.1478 22.0635 20.4507 21.1019 21.4122C20.1404 22.3738 18.8375 22.9157 17.4777 22.9198H7.21519C5.8554 22.9157 4.55247 22.3738 3.59095 21.4122C2.62943 20.4507 2.08746 19.1478 2.0834 17.788V7.5255C2.08746 6.16571 2.62943 4.86277 3.59095 3.90125C4.55247 2.93973 5.8554 2.39776 7.21519 2.3937H17.4777ZM17.4777 0.341309H7.21519C3.26381 0.341309 0.0310059 3.57411 0.0310059 7.5255V17.788C0.0310059 21.7394 3.26381 24.9722 7.21519 24.9722H17.4777C21.4291 24.9722 24.6619 21.7394 24.6619 17.788V7.5255C24.6619 3.57411 21.4291 0.341309 17.4777 0.341309Z"
              fill="white"
            />
            <path
              d="M19.0172 7.52539C18.7127 7.52539 18.4151 7.43511 18.162 7.26595C17.9088 7.0968 17.7115 6.85637 17.595 6.57508C17.4784 6.29378 17.448 5.98425 17.5074 5.68563C17.5668 5.38701 17.7134 5.11271 17.9287 4.89742C18.144 4.68213 18.4183 4.53551 18.7169 4.47611C19.0155 4.41671 19.325 4.4472 19.6063 4.56372C19.8876 4.68023 20.128 4.87754 20.2972 5.1307C20.4664 5.38386 20.5566 5.68149 20.5566 5.98596C20.5571 6.18825 20.5176 6.38862 20.4403 6.57559C20.3631 6.76256 20.2498 6.93244 20.1067 7.07547C19.9637 7.21851 19.7938 7.33189 19.6068 7.40909C19.4199 7.4863 19.2195 7.52583 19.0172 7.52539Z"
              fill="white"
            />
            <path
              d="M12.3464 8.55141C13.1584 8.55141 13.9521 8.79219 14.6272 9.24329C15.3024 9.69439 15.8285 10.3355 16.1393 11.0857C16.45 11.8359 16.5313 12.6613 16.3729 13.4577C16.2145 14.254 15.8235 14.9855 15.2493 15.5596C14.6752 16.1338 13.9437 16.5248 13.1473 16.6832C12.351 16.8416 11.5255 16.7603 10.7754 16.4496C10.0252 16.1388 9.38408 15.6127 8.93298 14.9375C8.48188 14.2624 8.24111 13.4687 8.24111 12.6567C8.24228 11.5683 8.67517 10.5248 9.44482 9.75512C10.2145 8.98548 11.258 8.55258 12.3464 8.55141ZM12.3464 6.49902C11.1286 6.49902 9.93803 6.86017 8.9254 7.53679C7.91276 8.2134 7.12351 9.17511 6.65745 10.3003C6.19139 11.4255 6.06945 12.6636 6.30704 13.8581C6.54464 15.0525 7.1311 16.1497 7.99228 17.0109C8.85345 17.8721 9.95065 18.4585 11.1451 18.6961C12.3396 18.9337 13.5777 18.8118 14.7029 18.3457C15.8281 17.8797 16.7898 17.0904 17.4664 16.0778C18.143 15.0652 18.5042 13.8746 18.5042 12.6567C18.5042 11.0236 17.8554 9.45737 16.7006 8.30258C15.5458 7.14778 13.9796 6.49902 12.3464 6.49902Z"
              fill="white"
            />
          </svg>
        </a>
      </Flex>
    </Layout>
  )
}

export default IndexPage
