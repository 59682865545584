import React from "react"

const Player = ({}) => (
  <div
    style={{ paddingTop: "56.6%", position: "relative", overflow: "hidden" }}
  >
    <iframe
      frameBorder="0"
      allowFullScreen=""
      scrolling="no"
      allow="autoplayfullscreen"
      src="https://onelineplayer.com/player.html?autoplay=false&autopause=false&muted=false&loop=false&url=https%3A%2F%2Fwww.dropbox.com%2Fs%2Fnl0cpqajs1lj5g9%2FIMG_5559.MP4%3Fraw%3D1&poster=https%3A%2F%2Ftwbtc.netlify.app%2Fcover.jpg&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true"
      style={{
        position: "absolute",
        height: "100%",
        width: " 100%",
        left: "0px",
        top: " 0px",
      }}
    ></iframe>
  </div>
)

export default Player
